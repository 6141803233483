<template>

  <PDialog
    :title="title"
    @close="close"
    classes="left-0 md:left-auto md:w-3/5 xl:w-5/12"
  >

    <div class="-mt-1 bg-white border-b border-gray-300">

      <div class="hidden md:block">
        <PTabs class="mx-4">
          <PTab
            v-for="(tab, index) in tabs"
            :key="index"
            v-model="activeTab"
            v-show="tab.visible"
            :name="tab.name"
            v-html="tab.html"
          ></PTab>
        </PTabs>
      </div>

      <div class="p-4 md:hidden">
        <PSelect
          class=""
          :items="tabs"
          v-model="activeTab"
          itemText="html"
          itemValue="name"
        />
      </div>

    </div>

    <EditDialogDetails
      v-show="activeTab === 'details'"
      :orderId="orderId"
      :showReal="showReal"
      :view="view"
      @close="close"
      @showWaybill="onShowWaybill"
    />

    <EditDialogHistory
      v-if="activeTab === 'history'"
      :orderId="orderId"
      @close="close"
    />

    <EditDialogSorts
      v-if="activeTab === 'sorts'"
      :orderId="orderId"
      @close="close"
    />

    <EditDialogComments
      v-if="activeTab === 'comments'"
      :orderId="orderId"
      @close="close"
    />

    <EditDialogWaybill
      v-if="activeTab === 'waybill'"
      :orderId="orderId"
      @close="close"
    />

    <EditDialogCustoms
      v-if="activeTab === 'customs'"
      :orderId="orderId"
      :transactions="order?.transactions"
      @close="close"
    />

  </PDialog>

</template>

<script>

import http from "@/http"
import { get } from "lodash"
import { mapGetters } from "vuex"

import EditDialogComments from "./EditDialog_Comments.vue"
import EditDialogCustoms from "./EditDialog_Customs.vue"
import EditDialogDetails from "./EditDialog_Details.vue"
import EditDialogHistory from "./EditDialog_History.vue"
import EditDialogSorts from "./EditDialog_Sorts.vue"
import EditDialogWaybill from "./EditDialog_Waybill.vue"

export default {

  props: {
    orderId: { type: String, required: true },
    showDetails: { type: Boolean, default: true },
    showHistory: { type: Boolean, default: true },
    showSorts: { type: Boolean, default: true },
    showComments: { type: Boolean, default: true },
    showWaybill: { type: Boolean, default: true },
    showCustoms: { type: Boolean, default: true },
    showReal: { type: Boolean, default: false },
    view: { type: String, default: "" },
    tab: { type: String, default: "" }
  },
  components: {
    EditDialogComments,
    EditDialogCustoms,
    EditDialogDetails,
    EditDialogHistory,
    EditDialogSorts,
    EditDialogWaybill,
  },
  data() {
    return {
      tabs: [],
      activeTab: "",
      order: null
    }
  },

  computed: {

    ...mapGetters(["location"]),

    transactionTypeId() {
      return get(this.order, "transactionTypeId", "")
    },
    waybillVisible() {
      return this.view === "expedition" || this.view === "orders" || this.view === "dispatch" || this.transactionTypeId === "TO" || this.transactionTypeId === "HE"
    },
    isPooling() {
      return this.location.isPooling
    },
    isSmartRetur() {
      return this.location.isSmartRetur
    },
    title() {

      const type = (this.isPooling || this.isSmartRetur) && this.transactionTypeId === "TO" ? this.$tk("Common.General.Order", true) :
        this.transactionTypeId === "TO" ? this.$tk("Common.General.Purchase", true) :
          (this.isPooling || this.isSmartRetur) && this.transactionTypeId === "HE" ? this.$tk("Common.General.Pickup", true) :
            this.transactionTypeId === "HE" ? this.$tk("Common.General.Sale", true) :
              this.transactionTypeId === "GL" ? this.$tk("Common.General.Delivery", true) :
                this.transactionTypeId === "OO" ? this.$tk("Common.General.Delivery", true) : ""

      const view = (this.isPooling || this.isSmartRetur) && this.view === "orders" ? this.$tk("Common.General.Order", true) :
        this.view === "orders" ? this.$tk("Common.General.Purchase", true) :
          (this.isPooling || this.isSmartRetur) && this.view === "dispatch" ? this.$tk("Common.General.Pickup", true) :
            this.view === "dispatch" ? this.$tk("Common.General.Sale", true) :
              this.view === "delivery" ? this.$tk("Common.General.Delivery", true) :
                this.view === "expedition" ? `${this.$tk("Common.General.Expedition", true)} ${this.$tk("Common.General.Of", true).toLowerCase()} ${type.toLowerCase()}` :
                  this.view === "reception" ? `${this.$tk("Common.General.Reception", true)} ${this.$tk("Common.General.Of", true).toLowerCase()} ${type.toLowerCase()}` : ""

      return this.order ? `${view} ${this.orderId}` : "&nbsp;"

    }
  },

  methods: {
    close() {
      this.$emit("close")
    },

    onShowWaybill() {
      this.activeTab = "waybill"
    },
  },

  async created() {

    this.tabs = [
      { name: 'details', html: this.$tk('Common.General.Details'), visible: this.showDetails },
      { name: 'history', html: this.$tk('Common.General.History'), visible: this.showHistory },
      { name: 'sorts', html: this.$tk('Common.General.Sortings'), visible: this.showSorts },
      { name: 'comments', html: this.$tk('Common.General.Comments'), visible: this.showComments },
      { name: 'waybill', html: this.$tk('Common.General.Waybill'), visible: this.showWaybill && this.waybillVisible },
    ]

    this.activeTab = this.tab || "details"
    this.order = await http.get("Order", { params: { id: this.orderId, withCustoms: true } })

    if (this.order.interCompanyIdTo) this.tabs.push({ name: 'customs', html: this.$tk('Common.General.Customs'), visible: this.showCustoms })
  }

}
</script>