<template>
  <details
    :id="'customs__' + transaction.id"
    :class="{ 'color-red-600': hasError }"
    :open="showCustoms"
  >
    <summary
      :aria-controls="'#customs__' + transaction.id"
      :aria-expanded="showCustoms"
      @click="showCustoms = !showCustoms"
    >
      <slot>
        <b>{{ transaction.name }}</b>
      </slot>
      <span class="text-gray-400 ml-2">({{ foreignPallets }} / plukket: {{ productQuantity }})</span>
      <div
        class="text-red-600"
        v-if="hasError"
      >
        <FontAwesomeIcon :icon="['far', 'exclamation-circle']" /> For mange paller til fortolling
      </div>
    </summary>
    <div
      v-if="isLoading"
      class="flex items-center mt-2"
    >
      <FontAwesomeIcon
        :icon="['far', 'spinner']"
        pulse
      />
      <div class="ml-2">
        <PTranslation k="Common.General.PleaseWait" />
      </div>
    </div>
    <table
      v-else
      class="mt-2"
    >
      <tr
        v-for="country in countries"
        :key="'heading__' + country.id"
      >
        <td class="flex flex-row gap-2 h-full">
          <img
            alt=""
            class="h-8 my-auto"
            :src="assetsBaseUrl + '/content/images/flags/' + country.id + '.png'"
          />
          <span class="my-auto">{{ country.name }}</span>
          <span class="my-auto text-gray-500">({{ country.id }})</span>
        </td>
        <td class="pl-2">
          <input
            :id="transaction.id + '__' + country.id"
            :class="inputClasses"
            :disabled="country.loading"
            :max="productQuantity"
            :min="0"
            type="number"
            :value="transactionCustoms.find(ct => ct.countryId === country.id)?.quantity || 0"
            @input="onInput"
          >
        </td>
      </tr>
    </table>
  </details>

</template>

<script>
import http from "@/http"

export default {
  props: ["transaction"],
  data() {
    return {
      countries: [],
      debounce: null,
      isLoading: false,
      showCustoms: false,
      transactionCustoms: [],
    }
  },
  watch: {
    foreignPallets() {
      if (this.foreignPallets > 0) this.showCustoms = true
    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    },
    inputClasses() {
      const classes = ["p-2 h-8 shadow-inner border rounded leading-none max-w-full w-24 focus:outline-none text-right"]
      if (this.hasError) classes.push("border-red-500 focus:border-red-600 placeholder-red-300 text-red-600")

      return classes
    },
    productQuantity() {
      return this.transaction.quantityPicked - this.transaction.quantityExtra
    },
    foreignPallets() {
      let pallets = 0
      this.transactionCustoms.forEach(custom => {
        if (custom.quantity > 0) pallets += custom.quantity
      })
      return pallets
    },
    hasError() {
      return this.foreignPallets > this.productQuantity
    },
    disableIncreaseButton() {
      return this.foreignPallets >= this.productQuantity
    },
  },
  methods: {
    async getCustomsCountries() {
      this.isLoading = true
      this.countries = await http.get('TransactionCustomsCountries')
      this.countries.map(c => c.loading = false)
      this.transactionCustoms = await http.get('TransactionCustoms?transactionId=' + this.transaction.id)
      this.isLoading = false
    },
    onInput(event) {
      const input = event.target
      const countryId = input?.id.split("__")[1]
      const quantity = event.target?.value || '0'

      // console.log("Checking for valid value: digit", !/^\d+$/.test(quantity), "error:", this.hasError)
      // if (!/^\d+$/.test(quantity) || this.hasError) return

      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => await this.saveTransactionCustoms(countryId, Number(quantity)), 700)
    },
    async saveTransactionCustoms(countryId, quantity) {
      try {
        const updateCountry = this.countries.find(country => country.id === countryId);
        if (updateCountry) updateCountry.loading = true
        const res = await http.post('TransactionCustom', { transactionId: this.transaction.id, countryId: countryId, quantity: quantity })
        this.transactionCustoms = res
        if (updateCountry) updateCountry.loading = false
      } catch (e) {
        console.error("could not update customs:", e)
      }
    }
  },
  mounted() {
    this.getCustomsCountries()
  }
}
</script>

<style scope>
.error {
  color: red;
}
</style>